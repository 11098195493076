import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 940,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
    background: {
      default: '#FFFFFF',
      paper: '#FFFFFF',
    },
    primary: {
      main: '#262E3F',
    },
    secondary: {
      main: '#B8A8B6',
    },
    grey: {
      50: '#E4ECF3',
    },
    success: {
      main: '#8AEBBC',
      contrastText: '#19702C',
    },
    error: {
      main: '#EB9B8A',
      contrastText: '#701919',
    },
  },
  typography: {
    fontFamily:
      'Avenir, sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, "Apple Color Emoji", "Segoe UI Emoji"',
    body1: {
      fontSize: 18,
    },
    body2: {
      fontSize: 18,
    },
    h1: {
      fontSize: 34,
      fontWeight: 'bold',
      marginTop: '5rem',
      marginBottom: '3rem',
    },
    h2: {
      fontSize: 30,
      fontWeight: 'bold',
      color: '#9D7E93',
      marginTop: '3.5rem',
    },
    h3: {
      fontSize: 30,
    },
    subtitle1: {
      fontSize: 18,
      fontWeight: 'bold',
      color: '#9D7E93',
    },
  },
  components: {
    MuiCardHeader: {
      styleOverrides: {
        avatar: {
          marginRight: 0,
        },
      },
    },
  },
});
