import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  BodyFatDistributionData,
  BodyFatPercentageData,
  BodyMassIndexData,
  BoneDensityData,
  DashboardAlert,
  GetWidgetDataResult,
  MassBreakdownData,
  RestingEnergyExpenditureData,
  VO2MaxData,
  VisceralFatData,
  WellnessScoreData,
} from './types';
import { store } from '../../store';
import { API } from '../../../_shared/utils/_urls';

const BASE_URL = process.env.REACT_APP_API_HOST as string;
const API_KEY = process.env.REACT_APP_API_KEY as string;

export const dashboardWidgetsApi = createApi({
  reducerPath: 'dashboardWidgetsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}`,
    prepareHeaders: headers => {
      headers.set('x-api-key', API_KEY);
      headers.set('Authorization', store.getState().currentUserState.token!);
      return headers;
    },
  }),
  refetchOnMountOrArgChange: true,
  endpoints: builder => ({
    getWidgetData: builder.query<
      | BodyFatPercentageData
      | BodyMassIndexData
      | RestingEnergyExpenditureData
      | VO2MaxData
      | BoneDensityData
      | VisceralFatData
      | MassBreakdownData
      | BodyFatDistributionData
      | WellnessScoreData,
      string
    >({
      query(widget) {
        return {
          url: `${API.DASHBOARD}/${widget}/`,
          method: 'GET',
        };
      },
      transformResponse: (result: GetWidgetDataResult) => {
        return result.data;
      },
    }),
    getAlert: builder.query<DashboardAlert, {}>({
      query() {
        return {
          url: `${API.DASHBOARD}/alert`,
          method: 'GET',
        };
      },
    }),
  }),
});

export const { useGetWidgetDataQuery, useGetAlertQuery } = dashboardWidgetsApi;
