import { lazy } from 'react';

const constructPath = (...args: string[]) =>
  args.join('').replace(/\/\//g, '/');

export interface PageInterface {
  name: string;
  componentPath: string;
  path: string;
  layout: string;
}

export interface RouteInterface {
  path: string;
  component: any;
  layout: string;
}

export interface InitializeRoutesInterface {
  routes: RouteInterface[];
}

export const initializeRoutes = (
  pages: PageInterface[],
  baseDir = './'
): InitializeRoutesInterface => {
  const routes: RouteInterface[] = [];

  for (const page of pages) {
    const route = {
      path: page.path,
      component: lazy(
        async () =>
          import(
            `${constructPath(baseDir, page.componentPath.replace('/*', ''))}`
          )
      ),
      layout: page.layout,
    };
    routes.push(route);
  }
  return {
    routes: routes.flat(),
  };
};
