import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  GetAllTestsResult,
  GetTestResult,
  HasPreviousTestsResult,
  HasUnscheduledTestsResult,
  HasUnscheduledTestsType,
  Test,
} from './types';
import { store } from '../../store';
import { API } from '../../../_shared/utils/_urls';

const BASE_URL = process.env.REACT_APP_API_HOST as string;
const API_KEY = process.env.REACT_APP_API_KEY as string;

export const testsApi = createApi({
  reducerPath: 'testsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}`,
    prepareHeaders: headers => {
      headers.set('x-api-key', API_KEY);
      headers.set('Authorization', store.getState().currentUserState.token!);
      return headers;
    },
  }),
  refetchOnMountOrArgChange: true,
  endpoints: builder => ({
    getAllTests: builder.query<Test[], {}>({
      query() {
        return {
          url: API.TEST,
          method: 'GET',
        };
      },
      transformResponse: (result: GetAllTestsResult) => {
        return result.data;
      },
    }),
    getAllUnscheduled: builder.query<Test[], {}>({
      query() {
        return {
          url: `${API.TEST}/unscheduled`,
          method: 'GET',
        };
      },
      transformResponse: (result: GetAllTestsResult) => {
        return result.data;
      },
    }),
    getHasPreviousTests: builder.query<boolean, string>({
      query(id) {
        return {
          url: `${API.TEST}/${id}/has-previous`,
          method: 'GET',
        };
      },
      transformResponse: (result: HasPreviousTestsResult) => {
        return result.data;
      },
    }),
    getHasUnscheduled: builder.query<HasUnscheduledTestsType, {}>({
      query() {
        return {
          url: `${API.TEST}/has-unscheduled`,
          method: 'GET',
        };
      },
      transformResponse: (result: HasUnscheduledTestsResult) => {
        return result.data;
      },
    }),
    // dismissHasUnscheduledTestAlert: builder.mutation<boolean, {}>({
    //   query() {
    //     return {
    //       url: '',
    //       method: 'POST',
    //     };
    //   },
    // }),
    getPreviousTests: builder.query<Test[], { id: string; max_tests: number }>({
      query({ id, max_tests }) {
        return {
          url: `${API.TEST}/${id}/previous/${max_tests}`,
          method: 'GET',
        };
      },
      transformResponse: (result: GetAllTestsResult) => {
        return result.data;
      },
    }),
    getTest: builder.query<Test, string>({
      query(id) {
        return {
          url: `${API.TEST}/${id}`,
          method: 'GET',
        };
      },
      transformResponse: (result: GetTestResult) => {
        return result.data;
      },
    }),
  }),
});

export const {
  useGetAllTestsQuery,
  useGetAllUnscheduledQuery,
  useGetHasPreviousTestsQuery,
  useGetPreviousTestsQuery,
  useGetTestQuery,
  useGetHasUnscheduledQuery,
} = testsApi;
