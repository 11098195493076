import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CurrentUser } from './types';
import { testsApi } from '../tests/testsApi';
import { profileApi } from '../profile/profileApi';
import storage from 'redux-persist/es/storage';
import { dashboardWidgetsApi } from '../dashboard/dashboardApi';

interface UserState {
  currentUser: CurrentUser | null;
  token: string | undefined;
}

const initialState: UserState = {
  currentUser: null,
  token: undefined,
};

export const currentUserSlice = createSlice({
  initialState,
  name: 'currrentUserSlice',
  reducers: {
    logout: state => {
      state.currentUser = null;
      state.token = undefined;
    },
    setCurrentUser: (
      state,
      action: PayloadAction<{
        currentUser: CurrentUser;
        token: string | undefined;
      }>
    ) => {
      state.currentUser = action.payload.currentUser;
      state.token = action.payload.token;
    },
    refreshUserState: (
      state,
      action: PayloadAction<{ currentUser: CurrentUser }>
    ) => {
      state.currentUser = action.payload.currentUser;
    },
  },
});

export default currentUserSlice.reducer;
export const { logout, setCurrentUser, refreshUserState } =
  currentUserSlice.actions;
