import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { GetProfileResult, Profile, UpdateProfileResult } from './types';
import { store } from '../../store';
import { API } from '../../../_shared/utils/_urls';

const BASE_URL = process.env.REACT_APP_API_HOST as string;
const API_KEY = process.env.REACT_APP_API_KEY as string;

export const profileApi = createApi({
  reducerPath: 'profileApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}`,
    prepareHeaders: headers => {
      headers.set('x-api-key', API_KEY);
      headers.set('Authorization', store.getState().currentUserState.token!);
      return headers;
    },
  }),
  refetchOnMountOrArgChange: true,
  endpoints: builder => ({
    getProfile: builder.query<Profile, {}>({
      query() {
        return {
          url: API.PROFILE,
          method: 'GET',
        };
      },
      transformResponse: (result: GetProfileResult) => {
        return result.data;
      },
    }),
    updateProfile: builder.mutation<UpdateProfileResult, {}>({
      query(data) {
        return {
          url: `${API.PROFILE}`,
          method: 'PUT',
          body: data,
        };
      },
      transformResponse: (result: any): UpdateProfileResult => {
        return result.data;
      },
    }),
  }),
});

export const { useGetProfileQuery, useUpdateProfileMutation } = profileApi;
