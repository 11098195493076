import React from 'react';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import ReactDOM from 'react-dom';
import App from './app/App';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { AuthProvider } from './context';
import { BrowserRouter } from 'react-router-dom';

import { datadogRum } from '@datadog/browser-rum';

datadogRum.init({
  applicationId: '13c5efda-60aa-4c00-b6a6-fbc4837189b0',
  clientToken: 'pub398c6f51eb7bd4ee705e4e69bfab2d28',
  site: 'us5.datadoghq.com',
  service: 'my_fitnescity_com',
  env: 'production',
  // version: '1.0.0',
  sessionSampleRate: 100,
  sessionReplaySampleRate: 100,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'allow',
});

const persistor = persistStore(store);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <AuthProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </AuthProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
