import styles from './scheduleLayout.module.css';
import { Container, Box } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Footer from '../../components/_shared/Footer';
import { Outlet } from 'react-router-dom';

const CheckoutLayout = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        backgroundColor: '#262e3f',
      }}
    >
      <CssBaseline />
      <Container className={styles.checkout_wrapper} maxWidth="lg">
        <img
          className={styles.logo}
          src="/img/logo.png"
          title="fitnescity logo"
        />
        <Outlet />
      </Container>
      <Footer />
    </Box>
  );
};

export default CheckoutLayout;
