import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  ApplyGiftCertificateRequest,
  ApplyGiftCertificateResult,
  ApplyPromoCodeRequest,
  ApplyPromoCodeResult,
  CheckoutSession,
  GetCheckoutSessionRequest,
  GetCheckoutSessionResult,
  LookupOrder,
  OrderLookupResult,
  PostCheckoutRequest,
  PostCheckoutResult,
  PreCheckoutRequest,
} from './types';

import { API } from '../../../_shared/utils/_urls';
import { store } from '../../store';

const BASE_URL = process.env.REACT_APP_API_HOST as string;
const API_KEY = process.env.REACT_APP_API_KEY as string;

export const checkoutApi = createApi({
  reducerPath: 'checkoutApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}`,
    prepareHeaders: headers => {
      headers.set('x-api-key', API_KEY);
      headers.set('Authorization', store.getState().currentUserState.token!);
      return headers;
    },
  }),
  refetchOnMountOrArgChange: true,
  endpoints: builder => ({
    getCheckoutSession: builder.query<
      CheckoutSession,
      GetCheckoutSessionRequest
    >({
      query(req) {
        let url = `${API.CHECKOUT}/${req.product_id}`;
        if (req.order_id !== null) {
          url += `?order_id=${req.order_id}`;
        }
        return {
          url: url,
          method: 'GET',
        };
      },
      transformResponse: (result: GetCheckoutSessionResult) => {
        return result.data;
      },
    }),
    lookupOrder: builder.query<LookupOrder, string>({
      query(appointment_id) {
        return {
          url: `${API.CHECKOUT}/order-lookup/${appointment_id}`,
          method: 'GET',
        };
      },
      transformResponse: (result: OrderLookupResult) => {
        return result.data;
      },
    }),
    preCheckout: builder.mutation<string, PreCheckoutRequest>({
      query(request) {
        return {
          url: `${API.CHECKOUT}/pre-checkout`,
          method: 'POST',
          body: request,
        };
      },
      transformResponse: (result: string) => {
        return result;
      },
    }),
    postCheckout: builder.mutation<CheckoutSession, PostCheckoutRequest>({
      query(request) {
        return {
          url: `${API.CHECKOUT}/post-checkout`,
          method: 'POST',
          body: request,
        };
      },
      transformResponse: (result: PostCheckoutResult) => {
        return result.data;
      },
    }),
    applyPromoCode: builder.mutation<CheckoutSession, ApplyPromoCodeRequest>({
      query(request) {
        console.log(request);
        return {
          url: `${API.CHECKOUT}/apply-promo-code`,
          method: 'POST',
          body: request,
        };
      },
      transformResponse: (result: ApplyPromoCodeResult) => {
        return result.data;
      },
    }),
    removePromoCode: builder.mutation<CheckoutSession, string>({
      query(order_id) {
        return {
          url: `${API.CHECKOUT}/remove-promo-code`,
          method: 'POST',
          body: { order_id: order_id },
        };
      },
      transformResponse: (result: ApplyPromoCodeResult) => {
        return result.data;
      },
    }),
    applyGiftCertificate: builder.mutation<
      CheckoutSession,
      ApplyGiftCertificateRequest
    >({
      query(request) {
        console.log(request);
        return {
          url: `${API.CHECKOUT}/apply-gift-certificate`,
          method: 'POST',
          body: request,
        };
      },
      transformResponse: (result: ApplyGiftCertificateResult) => {
        return result.data;
      },
    }),
    removeGiftCertificate: builder.mutation<CheckoutSession, string>({
      query(order_id) {
        return {
          url: `${API.CHECKOUT}/remove-gift-certificate`,
          method: 'POST',
          body: { order_id: order_id },
        };
      },
      transformResponse: (result: ApplyGiftCertificateResult) => {
        return result.data;
      },
    }),
  }),
});

export const {
  useGetCheckoutSessionQuery,
  useLookupOrderQuery,
  usePreCheckoutMutation,
  usePostCheckoutMutation,
  useApplyPromoCodeMutation,
  useRemovePromoCodeMutation,
  useApplyGiftCertificateMutation,
  useRemoveGiftCertificateMutation,
} = checkoutApi;
