import { get, round } from 'lodash';
import { Action } from '../../redux/type';
import moment from 'moment';
import createCache from '@emotion/cache';
import format from 'date-fns/format';
import toDate from 'date-fns/toDate';

export const friendlyName = (
  first_name: string | undefined,
  last_name: string | undefined
): string => {
  return `${first_name} ${last_name}`;
};

export function getNewLoadingState(
  currentState: Record<string, any> = {},
  action: Action,
  value: any
) {
  const { key } = action;
  return Object.assign({}, currentState, {
    uiLoaders: { ...currentState.uiLoaders, [key]: value },
  });
}

export function getAge(dateString: string) {
  const date = moment(dateString, 'YYYY-MM-DD');
  const years = moment().diff(date, 'years');
  const days = moment().diff(date.add(years, 'years'), 'days', false);
  return { years, days };
}

export const roundOff = (num: number | undefined | null, places: number) => {
  if (num === undefined || num === null) {
    return null;
  }
  return round(num, places).toFixed(places);
};

export const capitalizeFirstLetter = (str: string) => {
  return (
    str &&
    str
      .split(' ')
      .map(s => s.charAt(0).toUpperCase() + s.slice(1))
      .join(' ')
  );
};

export const arrayToById = (array: any[] = [], key = '_id') => {
  return array.reduce((accum, curr) => {
    accum[get(curr, key)] = curr;
    return accum;
  }, {});
};

//We could use date-fns or moment here but trying to limit the external references.
export function getShortDate(date: Date | undefined) {
  if (date !== undefined) {
    // const dateToFormat = new Date(date);
    // const year = dateToFormat.getFullYear();
    // const day = dateToFormat.getDay();
    // const month = dateToFormat.getMonth();
    // const formattedDated = `${month}/${day}/${year}`;
    // return formattedDated.toString();
    const dateToFormat = new Date(date);
    return format(dateToFormat, 'MM/dd/yyyy');
  }
  return '';
}

export function getLongDate(date: Date | undefined) {
  if (date !== undefined) {
    const dateToFormat = new Date(date);
    return format(dateToFormat, 'MMMM do, yyyy');
  }
  return '';
}

export function getShortDateFromString(date: string): string {
  const realDate = new Date(date.substring(0, 19));
  return format(realDate, 'MM/dd/yyyy');
}

export const formatDateTime = (date: Date | undefined): string => {
  if (date !== undefined) {
    return format(new Date(date), 'MM/dd/yyyy hh:mm aa');
  }
  return '';
};

export const formatTime = (date: Date | undefined): string => {
  if (date !== undefined) {
    return format(new Date(date), 'h:mm aa');
  }
  return '';
};

export const formatTimeString = (date: string | undefined): string => {
  if (date === undefined) return '';
  const newDate = new Date(date);
  return newDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
};

/**
 * Get first character from first & last sentences of a username
 * @param {String} name - Username
 * @return {String} 2 characters string
 */
export const getNameInitial = (name: string) => {
  const initials = name.match(/\b\w/g) || [];
  return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
};

export const createEmotionCache = () => {
  return createCache({ key: 'css', prepend: true });
};

export const PASSWORD_PATTERN =
  /^(?=[^A-Z\n]*[A-Z])(?=[^a-z\n]*[a-z])(?=[^0-9\n]*[0-9])(?=[^#?!@$%^&*\n-]*[#?!@$%^&*-]).{8,}$/;

export const getOrdinalNumber = (num: number): string => {
  let ordinal: string;
  switch (num) {
    case 1:
    case 21:
    case 31:
    case 41:
    case 51:
    case 61:
    case 71:
    case 81:
    case 91:
      ordinal = 'st';
      break;
    case 2:
    case 22:
    case 32:
    case 42:
    case 52:
    case 62:
    case 72:
    case 82:
    case 92:
      ordinal = 'nd';
      break;
    case 3:
    case 23:
    case 33:
    case 43:
    case 53:
    case 63:
    case 73:
    case 83:
    case 93:
      ordinal = 'rd';
      break;
    default:
      ordinal = 'th';
      break;
  }
  if (num >= 100) {
    return `99th`;
  } else {
    return `${num}${ordinal}`;
  }
};

export const checkNumberIsEmpty = (value: any): boolean => {
  if (value === undefined) return true;
  else if (value === '') return true;
  else if (value === '0') return true;
  else if (value === null) return true;
  else if (value.length === 0) return true;
  else if (value === 0) return true;
  return false;
};

export const formatHeightImperial = (value: number | undefined): string => {
  if (value !== undefined) {
    const feet = value / 12;
    const inches = value % 12;
    return `${Math.floor(feet)}'${roundOff(inches, 0)}"`;
  }
  return '';
};

export const getTestFriendlyName = (test_type: string): string => {
  if (test_type === 'DEXA') return 'DEXA Scan';
  else if (test_type === 'RMR') return 'RMR Test';
  else if (test_type === 'DEXABMD') return 'DEXA Bone Density Test';
  else if (test_type === 'HYDROSTATIC') return 'Hydrostatic Test';
  else if (test_type === 'VO2') return 'VO2 Max Test';
  else if (test_type === 'SECA') return 'seca Test';
  else if (test_type === 'BODPOD') return 'BOD POD';
  else return test_type;
};
