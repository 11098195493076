import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  UpdateFormAsSignedResultType,
  FormResultType,
  GetFormResultType,
  FormsResultType,
  GetFormsResultType,
  FormType,
  GetDownloadResultType,
  DownloadFormType,
} from './types';
import { store } from '../../store';

const BASE_URL = process.env.REACT_APP_API_HOST as string;
const API_KEY = process.env.REACT_APP_API_KEY as string;

export const formsApi = createApi({
  reducerPath: 'formApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}`,
    prepareHeaders: headers => {
      headers.set('x-api-key', API_KEY);
      const authToken = store.getState().currentUserState.token;
      if (authToken) {
        headers.set('Authorization', authToken);
      }
      return headers;
    },
  }),
  endpoints: builder => ({
    getFormToSign: builder.query<FormResultType, string>({
      query(signature_request_id) {
        return {
          url: `forms/sign-url/${signature_request_id}`,
          method: 'GET',
        };
      },
      transformResponse: (result: GetFormResultType) => {
        return result.data;
      },
    }),
    getSignedForms: builder.query<FormType[], {}>({
      query() {
        return {
          url: `forms/customer`,
          method: 'GET',
        };
      },
      transformResponse: (result: GetFormsResultType) => {
        console.log(result);
        return result.data.forms;
      },
    }),
    getDownloadUrl: builder.query<DownloadFormType, string>({
      query(id: string) {
        return {
          url: `forms/download/${id}`,
          method: 'GET',
        };
      },
      transformResponse: (result: GetDownloadResultType) => {
        return result.data;
      },
    }),
    updateFormAsSigned: builder.mutation<UpdateFormAsSignedResultType, any>({
      query(payload) {
        return {
          url: `/forms/signed`,
          method: 'POST',
          body: payload,
        };
      },
      transformResponse: (result: any) => {
        return result.data;
      },
    }),
  }),
});

export const {
  useGetFormToSignQuery,
  useGetSignedFormsQuery,
  useUpdateFormAsSignedMutation,
  useGetDownloadUrlQuery,
} = formsApi;
