export const APP = {
  INDEX: '/',
  LOGIN: '/auth/login',
  VERIFY_CODE: '/auth/verify',
  VERIFY_MESSAGE: '/auth/verify-message',
  VERIFY_LINK: '/auth/verifyLink',
  FORGOT_PASSWORD: '/auth/reset-password',
  DASHBOARD: '/dashboard',
  TESTS: '/tests',
  WHATSNEXT: '/whats-next',
  PROFILE: '/profile',
  BOOK_BODY_COMP: 'https://www.fitnescity.com/states',
  BOOK_RMR: 'https://www.fitnescity.com/states',
  BOOK_VO2: 'https://www.fitnescity.com/states',
  BOOK_BODPOD: 'https://www.fitnescity.com/states',
  TEST_DEXA: '/test/dexa',
  TEST_DEXA_BMD: '/test/dexa-bmd',
  TEST_RMR: '/test/rmr',
  TEST_BMI: '/test/bmi',
  TEST_BODPOD: '/test/bod-pod',
  TEST_VO2: '/test/vo2',
  HYDROSTATIC: '/test/hydrostatic',
  SECA: '/test/seca',
  SCHEDULE: '/scheduling/schedule',
};

export const API = {
  LOGIN: '/auth/login',
  VERIFY_LINK: '/auth/verify-link',
  VERIFY_ACCOUNT: '/auth/verify-account',
  CHECK_VERIFICATION: '/auth/check-verification',
  RESEND_VERIFICATION: '/auth/resend-verification',
  RESET_PASSWORD: '/auth/reset-password',
  UPDATE_PASSWORD: '/auth/update-password',
  CHANGE_PASSWORD: '/auth/change-password',
  USERS: '/users',
  PROFILE: '/customer/profile',
  TOOLTIP: '/customer/tooltip',
  TEST: '/customer/tests',
  DASHBOARD: '/customer/dashboard',
  CHECKOUT: '/customer/checkout',
  SCHEDULING: '/scheduling',
};
