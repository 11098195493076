import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  BookAppointmentRequestType,
  BookAppointmentResultType,
  DownloadFileResultType,
  FormToSignType,
  FormsToSignResultType,
  GetAvailabilityRequestType,
  GetFormsToSignResultType,
  GetPrescreenResultType,
  GetTestAvailabilityResultType,
  GetTestInfoResultType,
  PrescreenResultType,
  TestAvailabilityResultType,
  TestInfoResultType,
  UpdateFormAsSignedResultType,
  VO2PrescreenResultType,
} from './types';

import { API } from '../../../_shared/utils/_urls';
import { store } from '../../store';

const BASE_URL = process.env.REACT_APP_API_HOST as string;
const API_KEY = process.env.REACT_APP_API_KEY as string;

export const schedulingApi = createApi({
  reducerPath: 'schedulingApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}`,
    prepareHeaders: headers => {
      headers.set('x-api-key', API_KEY);
      headers.set('Authorization', store.getState().currentUserState.token!);
      return headers;
    },
  }),
  refetchOnMountOrArgChange: true,
  endpoints: builder => ({
    getTestInfo: builder.query<TestInfoResultType, string>({
      query(test_id) {
        const url = `${API.SCHEDULING}/test-info/${test_id}`;
        return {
          url: url,
          method: 'GET',
        };
      },
      transformResponse: (result: GetTestInfoResultType) => {
        return result.data;
      },
    }),
    getAvailability: builder.query<
      TestAvailabilityResultType,
      GetAvailabilityRequestType
    >({
      query(req) {
        let url = `${API.SCHEDULING}/availability/${req.test_id}?month=${req.month}&year=${req.year}`;
        if (req.number_of_months !== undefined) {
          url += `?number_of_months=${req.number_of_months}`;
        }
        return {
          url: url,
          method: 'GET',
        };
      },
      transformResponse: (result: GetTestAvailabilityResultType) => {
        return result.data;
      },
    }),
    bookAppointment: builder.mutation<
      BookAppointmentResultType,
      BookAppointmentRequestType
    >({
      query(request) {
        return {
          url: `${API.SCHEDULING}/book-appointment`,
          method: 'POST',
          body: request,
        };
      },
      transformResponse: (result: any) => {
        return result.data;
      },
    }),
    getFormsToSign: builder.query<FormsToSignResultType, string>({
      query(test_id) {
        const url = `${API.SCHEDULING}/forms/${test_id}`;
        return {
          url: url,
          method: 'GET',
        };
      },
      transformResponse: (result: GetFormsToSignResultType) => {
        return result.data;
      },
    }),
    updateFormAsSigned: builder.mutation<UpdateFormAsSignedResultType, any>({
      query(payload) {
        console.log('this will be the id:', payload);
        return {
          url: `${API.SCHEDULING}/forms/signed`,
          method: 'POST',
          body: payload,
        };
      },
      transformResponse: (result: any) => {
        return result.data;
      },
    }),
    getPrescreen: builder.query<PrescreenResultType, string>({
      query(test_id) {
        const url = `${API.SCHEDULING}/prescreen/${test_id}`;
        return {
          url: url,
          method: 'GET',
        };
      },
      transformResponse: (result: GetPrescreenResultType) => {
        return result.data;
      },
    }),
    validatePrescreen: builder.mutation<VO2PrescreenResultType, any>({
      query(payload) {
        return {
          url: `${API.SCHEDULING}/prescreen`,
          method: 'POST',
          body: payload,
        };
      },
      transformResponse: (result: any) => {
        return result.data;
      },
    }),
    getFormDownload: builder.query<DownloadFileResultType, string>({
      query(form_id) {
        const url = `${API.SCHEDULING}/forms/${form_id}/download`;
        return {
          url: url,
          method: 'GET',
        };
      },
      transformResponse: (result: any) => {
        return result.data;
      },
    }),
  }),
});

export const {
  useGetPrescreenQuery,
  useGetAvailabilityQuery,
  useGetTestInfoQuery,
  useBookAppointmentMutation,
  useGetFormsToSignQuery,
  useUpdateFormAsSignedMutation,
  useValidatePrescreenMutation,
  useGetFormDownloadQuery,
} = schedulingApi;
