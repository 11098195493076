import styles from './checkoutLayout.module.css';
import { Container, Box } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Footer from '../../components/_shared/Footer';
import { Outlet } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from 'react-device-detect';

const CheckoutLayout = () => {
  if (isMobile) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
        }}
      >
        <Helmet>
          <script type="text/javascript">{`(function(a,b,c,d,e,f,g){e['ire_o']=c;e[c]=e[c]||function(){(e[c].a=e[c].a||[]).push(arguments)};f=d.createElement(b);g=d.getElementsByTagName(b)[0];f.async=1;f.src=a;g.parentNode.insertBefore(f,g);})('https://utt.impactcdn.com/A4295455-0357-4a4e-9f66-c9b0af026b201.js','script','ire',document,window); ire('identify', {customerid: '', customeremail: ''}); `}</script>
        </Helmet>
        <CssBaseline />
        <Outlet />
      </Box>
    );
  } else {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
          backgroundColor: '#262e3f',
        }}
      >
        <Helmet>
          <script type="text/javascript">{`(function(a,b,c,d,e,f,g){e['ire_o']=c;e[c]=e[c]||function(){(e[c].a=e[c].a||[]).push(arguments)};f=d.createElement(b);g=d.getElementsByTagName(b)[0];f.async=1;f.src=a;g.parentNode.insertBefore(f,g);})('https://utt.impactcdn.com/A4295455-0357-4a4e-9f66-c9b0af026b201.js','script','ire',document,window); ire('identify', {customerid: '', customeremail: ''}); `}</script>
        </Helmet>
        <CssBaseline />
        <Container maxWidth="xl" className={styles.checkout_wrapper}>
          <img
            className={styles.logo}
            src="/img/logo.png"
            title="fitnescity logo"
            onClick={(e: any) => {
              e.preventDefault();
              window.location.href = 'https://www.fitnescity.com/states';
            }}
          />
          <Outlet />
        </Container>
        <Footer />
      </Box>
    );
  }
};

export default CheckoutLayout;
