import { Suspense, lazy } from 'react';
import './App.css';
import Loading from '../components/_shared/Loading';
import 'react-toastify/dist/ReactToastify.css';
import { Slide, ToastContainer } from 'react-toastify';
import { ThemeProvider } from '@mui/material/styles';
import { CacheProvider } from '@emotion/react';
import { createEmotionCache } from '../_shared/utils/helpers';
import { theme } from '../theme';
import ScrollToTop from './scrollToTop';
import { Routes, Route } from 'react-router-dom';
import { map, filter } from 'lodash';
import { useRoutes } from '../hooks/useRoutes';
import { RouteInterface } from '../pages/routes.config';
import CheckoutLayout from './checkoutLayout';
import ScheduleLayout from './scheduleLayout';

const Layout = lazy(() => import('../app/layout'));
const DashboardLayout = lazy(() => import('../app/dashboardLayout'));
const PreviewLayout = lazy(() => import('../app/previewLayout'));
const clientSideEmotionCache = createEmotionCache();

const routesBuilder = (
  layout: string,
  routes: RouteInterface[],
  routePrepend?: string | undefined
) => {
  const matchedRoutes = filter(routes, (r: RouteInterface) => {
    return r.layout === layout;
  });
  return map(matchedRoutes, (mr: RouteInterface) => {
    return (
      <Route
        path={routePrepend ? routePrepend + mr.path : mr.path}
        Component={mr.component}
      />
    );
  });
};

const App = () => {
  const routes = useRoutes();

  return (
    <CacheProvider value={clientSideEmotionCache}>
      <ThemeProvider theme={theme}>
        <ToastContainer
          containerId={1}
          transition={Slide}
          autoClose={5000}
          hideProgressBar={true}
          newestOnTop={false}
          enableMultiContainer={false}
          closeOnClick={true}
          rtl={false}
          draggable={false}
          pauseOnHover={true}
          position="top-right"
        />
        <Suspense fallback={<Loading />}>
          <Routes>
            <Route element={<ScrollToTop />}>
              <Route element={<Layout />}>
                {routesBuilder('default', routes.routes)}
                {routesBuilder('results', routes.routes)}
                <Route />
              </Route>
              <Route path={'/preview'} element={<PreviewLayout />}>
                {routesBuilder('results', routes.routes, '/preview')}
              </Route>
              <Route element={<DashboardLayout />}>
                {routesBuilder('dashboard', routes.routes)}
              </Route>
              <Route element={<CheckoutLayout />}>
                {routesBuilder('checkout', routes.routes)}
              </Route>
              <Route element={<ScheduleLayout />}>
                {routesBuilder('schedule', routes.routes)}
              </Route>
              {routesBuilder('auth', routes.routes)}
            </Route>
          </Routes>
        </Suspense>
      </ThemeProvider>
    </CacheProvider>
  );
};

export default App;
