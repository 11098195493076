import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { GetTooltipResult, CreateTooltipResult } from './types';
import { store } from '../../store';
import { API } from '../../../_shared/utils/_urls';

const BASE_URL = process.env.REACT_APP_API_HOST as string;
const API_KEY = process.env.REACT_APP_API_KEY as string;

export const tooltipApi = createApi({
  reducerPath: 'tooltipApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}`,
    prepareHeaders: headers => {
      headers.set('x-api-key', API_KEY);
      headers.set('Authorization', store.getState().currentUserState.token!);
      return headers;
    },
  }),
  refetchOnMountOrArgChange: true,
  endpoints: builder => ({
    getTooltip: builder.query<GetTooltipResult, string>({
      query(tooltip_id) {
        return {
          url: `${API.TOOLTIP}/${tooltip_id}`,
          method: 'GET',
        };
      },
    }),
    createTooltip: builder.mutation<CreateTooltipResult, string>({
      query(tooltip_id) {
        return {
          url: `${API.TOOLTIP}/${tooltip_id}`,
          method: 'POST',
        };
      },
    }),
  }),
});

export const { useGetTooltipQuery, useCreateTooltipMutation } = tooltipApi;
