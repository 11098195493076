import { ReactNodeArray } from 'prop-types';
import { createContext, ReactNode } from 'react';
import { store } from '../../redux/store';
import { CurrentUser } from '../../redux/features/auth/types';

export type AuthProviderStateType = {
  auth: CurrentUser | null | undefined;
  sessionToken?: string;
  getLoggedIn?: (user: any) => void;
};

const initialState: AuthProviderStateType = {
  auth: undefined,
  sessionToken: '',
};

type AuthProviderProps = {
  children: ReactNode | ReactNodeArray;
};

export const AuthContext = createContext(initialState);

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const currentUser = store.getState().currentUserState;

  const contextValue = {
    auth: currentUser.currentUser,
    sessionToken: currentUser.token,
    //getLoggedIn: //Todo: Relate to method that refreshes user.
    currentUser: currentUser.currentUser,
  };
  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};
