import { Container } from '@mui/material';
import Box from '@mui/material/Box';
import styles from './footer.module.css';

const Footer = () => {
  return (
    <Box
      component="footer"
      className={styles.footerWrapper}
      sx={{
        pt: 4,
        mt: 'auto',
      }}
    >
      <Box className={styles.footer} sx={{ pb: 2 }}>
        <Container>
          <p>3 Columbus Circle, New York, NY 10019</p>
          <p>Made with ❤️ in New York</p>
          <p>
            <img
              className={styles.logo}
              src="/img/logo_footer.png"
              title="fitnescity logo"
            />
          </p>
        </Container>
      </Box>
    </Box>
  );
};

export default Footer;
